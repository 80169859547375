

















































import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';
import FolderHistory from '@/components/dashboard/FolderHistory.vue';
import ChemiFolderCard from '@modules/chemical-risk/components/ChemiFolderCard.vue';
import ChemiFolderForm from '@modules/chemical-risk/components/form/ChemiFolderForm.vue';

import Firm from '@models/firm/Firm';
import CheFolder from '@modules/chemical-risk/entities/CheFolder';

const cheRiskModule = namespace('cheRisk');

@Component({
  components: {
    Layout,
    ChemiFolderForm,
    ChemiFolderCard,
    FolderHistory,
  },
})
export default class DashboardView extends Vue {
  @cheRiskModule.Getter('getHistoricCheFolders')
  getHistoricCheFolders!: () => Array<CheFolder>;

  @cheRiskModule.Getter('getActiveCheFolder')
  activeCheFolder!: CheFolder | null;

  @cheRiskModule.Action('loadProducts')
  loadProducts!: (payload: any) => any;

  @Getter('currentFirm')
  firm!: Firm;

  filters: any = {
    name: '',
    vendor: '',
    orderColumn: '',
    order: '',
  };

  totalProducts: number = 0;

  async created() {
    const filters = {
      page: 0,
      limit: 0,
      metaOnly: true,
    };

    const data = await this.loadProducts({ filters });
    this.totalProducts = data.meta.total;
  }
}
