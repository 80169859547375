



































































































import { namespace } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';

import FolderCard from '@/components/dashboard/FolderCard.vue';
import CmrChart from '@/components/chart/CmrChart';
import SensibilityChart from '@/components/chart/SensibilityChart';
import Modal from '@/components/Modal.vue';

import CheFolder from '@modules/chemical-risk/entities/CheFolder';

const cheRiskModule = namespace('cheRisk');

@Component({
  components: {
    FolderCard,
    SensibilityChart,
    CmrChart,
    Modal,
  },
})
export default class ChemiFolderCard extends Vue {
  @Prop({ type: Object })
  cheFolder!: CheFolder;

  @Prop({ type: String })
  title!: string;

  @Prop({ type: Number })
  status!: number;

  @Prop({ type: Number })
  nbInventory!: number;

  @Prop({ type: Array })
  cmrChart!: Array<Number>;

  @Prop({ type: Array })
  sensibilityChart!: Array<Number>;

  @cheRiskModule.Action('analyseCheFolder')
  analyseCheFolder!: (id: number) => any;

  @cheRiskModule.Action('getPreviewPDF')
  getPreviewPDF!: (id: any) => any;

  @cheRiskModule.Action('getSeirich')
  getSeirich!: () => any;

  @cheRiskModule.Action('loadProductsFolder')
  loadProductsFolder: any;

  loading: boolean = false;

  filters: any = {
    isNotCompleted: true,
    page: 1,
    limit: 10,
  };

  async handleExpertValidation() {
    const notAnalysedProduct = await this.loadProductsFolder({ page: this.filters.page, filters: this.filters });
    if (notAnalysedProduct.items.length > 0) {
      this.$message({
        type: 'warning',
        message: 'Soumission impossible. Un ou plusieurs produits n\'ont pas été analysés.',
      });
    } else {
      this.$confirm('Vous ne pourrez plus modifier votre dossier et vos produits pendant l\'analyse par le comité d\'experts. Continuer?', 'Attention', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning',
      }).then(async () => {
        await this.analyseCheFolder(this.cheFolder.id ? this.cheFolder.id : 0);
        this.$message({
          type: 'success',
          message: 'Votre dossier est désormais en cours d\'analyse',
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Demande annulée',
        });
      });
    }
  }

  downloadPreviewPDF() {
    this.getPreviewPDF(this.cheFolder.id);
  }

  downloadSeirich() {
    this.getSeirich();
  }
}
