



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FolderCard from '@/components/dashboard/FolderCard.vue';

import CheFolder from '@modules/chemical-risk/entities/CheFolder';

const cheRiskModule = namespace('cheRisk');

@Component({
  components: {
    FolderCard,
  },
})
export default class ChemiFolderForm extends Vue {
  @cheRiskModule.Getter('getFolder')
  getFolder!: (id: number) => CheFolder;

  @cheRiskModule.Action('postApiActiveChemRiskFolder')
  postApiActiveChemRiskFolder!: (form: any) => any;

  btnDisabled: boolean = false;
  cheFolderForm: any = {
    cloneId: 0,
    reference: '',
  };

  get rules() {
    return {
      cloneId: [
        { required: true, message: 'Veuillez saisir une valeur', trigger: 'blur' },
      ],
      reference: [
        { required: this.cheFolderForm.cloneId === 0, message: 'Veuillez saisir une description', trigger: 'blur' },
      ],
    };
  }

  setReference() {
    const cheFold = this.getFolder(this.cheFolderForm.cloneId);
    this.cheFolderForm.reference = cheFold ? cheFold.reference : null;
  }

  submitForm() {
    this.btnDisabled = true;
    (this.$refs.form as Vue & { validate: () => Promise<void> }).validate()
      .then(async () => {
        const response = await this.postApiActiveChemRiskFolder(this.cheFolderForm);
        this.$message({
          type: 'success',
          message: 'Données mises à jour',
        });
      })
      .catch((error) => {
        let referenceErrors;
        try {
          referenceErrors = error.response.data.errors.children.reference.errors;
        } catch (e) {
          referenceErrors = undefined;
        }

        if (referenceErrors !== undefined && referenceErrors.includes('PRODUCT_WITHOUT_FDS')) {
          // Il ya une erreur car des produits sont sans FDS
          this.$message({
            type: 'error',
            message: 'Vous ne pouvez pas créer de dossier tant que tous les produits de l\'inventaire n\'ont pas une FDS',
          });
        } else {
          // Toutes les autres erreurs
          this.$message({
            type: 'info',
            message: 'Erreur lors de la création d\'un dossier',
          });
        }
        this.btnDisabled = false;
      });
  }
}
